<template>
  <div class="space-y-5">
    <div class="flex items-center justify-between flex-row-reverse lg:flex-row">
      <h1 class="page_title">{{ $route.meta.title }}</h1>

      <router-link v-if="is_API_loaded" :to="{ name: 'new-coupon' }">
        <vs-button size="large"><span class="font-medium">新增優惠碼</span></vs-button>
      </router-link>

      <!-- 骨架屏 -->
      <skeleton v-else width="82px" />
    </div>

    <main v-if="is_API_loaded" class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <vs-input class="w-full" v-model="searchTitle" @blur="search" @keypress.enter="search" placeholder="搜尋活動名稱" />
        <!-- <vs-select style="max-width: 200px" multiple filter placeholder="篩選狀態" v-model="filtered" @change="statusOptions">
          <vs-option label="公開" value="1">已公開</vs-option>
          <vs-option label="隱藏" value="0">已隱藏</vs-option>
        </vs-select> -->
      </section>

      <section class="text-sm overflow-x-auto">
        <!-- 標頭區塊 -->
        <div class="header_wrap text-gray-text_dark font-medium flex items-center px-4 h-14">
          <vs-checkbox v-if="indeterminate" indeterminate v-model="indeterminate" class="checkbox_section" @click="indeterminateHandleClick"
            ><span class="text-xs">已選取 {{ selected.length }} 筆</span></vs-checkbox
          >

          <vs-checkbox v-else class="checkbox_section" v-model="selectAll" @click="checkAllHandleClick">
            <span v-if="selectAll" class="text-xs">已選取 {{ selected.length }} 筆</span>
            <span v-else class="text-xs">共 {{ lists.length }} 筆</span>
          </vs-checkbox>

          <transition name="fade">
            <div v-if="indeterminate || selectAll" class="" key="1">
              <div class="w-max border border-gray-border rounded-xl grid grid-flow-col h-8 divide-x divide-gray-border overflow-hidden">
                <button @click="deletePages_dialog = true" class="px-4 hover:bg-gray-background font-medium">刪除</button>
              </div>
            </div>
            <div v-else class="grid_section" key="2">
              <span>活動名稱</span>
              <!-- <span>狀態</span> -->
              <span>開始時間</span>
              <span>結束時間</span>
            </div>
          </transition>
        </div>

        <!-- 列表區塊 -->
        <div class="header_wrap flex flex-col divide-y divide-gray-background text-gray-text_light">
          <router-link
            v-for="item in lists"
            :key="item.smcno"
            :to="{
              name: 'edit-coupon',
              params: {
                id: item.smcno,
              },
            }"
            class="flex items-center h-14 hover:bg-gray-background transition-colors cursor-pointer px-4 relative group"
          >
            <vs-checkbox class="checkbox_section" @click.stop="checkBoxHandleClick" v-model="selected" :val="item.smcno"></vs-checkbox>
            <section class="grid_section">
              <span class="text-limit-1">{{ item.title }}</span>
              <!-- <div class="w-36">
                  <span v-if="item.isrtime" class="bg-primary text-white rounded-full px-3 py-1">已公開</span>
                  <span v-else-if="!item.isrtime && isSchedule(item.rtime)" class="bg-warning text-white rounded-full px-3 py-1"
                    >排程中</span
                  >
                  <span v-else class="bg-gray-background text-gray-text_light rounded-full px-3 py-1">已隱藏</span>
                </div> -->
              <span>{{ item.vdate }}</span>
              <span>{{ item.edate }}</span>
            </section>
          </router-link>
        </div>
      </section>
    </main>

    <!-- 骨架屏區塊 -->
    <main v-else class="bg-white rounded-2xl w-full pt-4">
      <section class="flex space-x-3 px-4 mb-2">
        <skeleton />
        <skeleton class="sm:flex-shrink-0" :width="200" />
      </section>
      <section class="text-sm overflow-x-auto">
        <div v-for="(item, index) in 3" :key="index" class="text-gray-text_dark font-medium flex items-center px-4 h-14 w-full">
          <div class="checkbox_section">
            <skeleton :width="23" :height="23" />
          </div>
          <skeleton width="100%" height="23px" />
        </div>
      </section>
    </main>

    <!-- pagination -->
    <div v-if="pagination.tpages > 1" class="flex flex-col items-center space-y-2">
      <vs-pagination @click.native="changePagination" not-arrows v-model="currentPage" :length="pagination.tpages" />
      <span class="text-xs text-gray-400">總共 {{ pagination.pcounts }} 筆</span>
    </div>

    <!-- 刪除行銷活動 dialog -->
    <vs-dialog overflow-hidden blur v-model="deletePages_dialog" not-center>
      <template #header>
        <h1 class="text-base font-medium text-gray-text_dark">是否要刪除 {{ selected.length }} 筆行銷活動？</h1>
      </template>

      <div class="mb-3">
        <p class="text-sm text-gray-text_light">刪除的行銷活動無法復原，確定仍要繼續？</p>
      </div>

      <template #footer>
        <div class="flex justify-end space-x-4">
          <vs-button size="large" transparent @click="deletePages_dialog = false">取消</vs-button>
          <vs-button @click="bathDelete" size="large" transparent color="danger" ref="deletePagesBtn">刪除</vs-button>
        </div>
      </template>
    </vs-dialog>
  </div>
</template>

<script>
export default {
  name: 'coupons',
  data() {
    return {
      is_API_loaded: false,
      lists: [],
      pagination: [],
      selectAll: false,
      indeterminate: false,
      selected: [],
      deletePages_dialog: false,
      searchTitle: '', // 搜尋活動名稱
      searchTitleBak: '', // 搜尋活動名稱備份
      defaultQuery: {},
      currentPage: 1,
    }
  },
  created() {
    let query = this.$route.query
    this.defaultQuery = query
    this.searchTitle = query.title || ''
    this.searchTitleBak = query.title || ''
    this.getList()
  },
  methods: {
    // 3.5.4.1 讀取優惠券列表
    getList(arr) {
      // 深拷貝
      let routeQuery = JSON.parse(JSON.stringify(this.$route.query))

      // 新增或覆蓋進routeQuery物件
      if (arr && arr.length > 0) {
        arr.forEach((item) => {
          routeQuery[item[0]] = item[1]
        })
      }

      // 迴圈，若物件中有空值就刪除
      for (let key in routeQuery) {
        // 若值為空，刪除。
        if (routeQuery[key] === '') {
          delete routeQuery[key]
        }
      }

      // 判斷物件是否一樣
      function objCompare(obj1, obj2) {
        const obj1_keys = Object.keys(obj1)
        const obj2_keys = Object.keys(obj2)
        if (obj1_keys.length !== obj2_keys.length) {
          return false
        }
        for (let key of obj1_keys) {
          if (obj1[key] !== obj2[key]) {
            return false
          }
        }
        return true
      }

      if (!objCompare(routeQuery, this.defaultQuery)) {
        // 不一樣，重整query
        this.$router.replace({ query: routeQuery })
        this.defaultQuery = routeQuery
      }

      this.$axios({
        url: 'front/store/market/getCouponList.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          page: routeQuery.page || 1,
          title: routeQuery.title || '',
        },
      }).then((res) => {
        if (res.data.Success) {
          this.is_API_loaded = true
          this.lists = res.data.Data
          this.pagination = res.data.Page

          console.log('讀取優惠券列表', res.data)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 批次操作 3.5.4.5 優惠券資料-刪除
    bathDelete() {
      const loading = this.$vs.loading({
        target: this.$refs.deletePagesBtn,
        background: 'danger',
        scale: '0.6',
        opacity: 1,
        color: '#fff',
      })

      this.$axios({
        url: 'front/store/market/dCouponData.php',
        headers: {
          stoken: this.$store.state.login.userInfo.stoken,
        },
        kcval: true,
        data: {
          lang: 0,
          smcno: this.selected.toString(),
        },
      }).then((res) => {
        loading.close()
        if (res.data.Success) {
          this.selected = []
          this.indeterminate = false
          this.selectAll = false
          this.deletePages_dialog = false // 關閉dialog
          this.getList([['page', 1]])
          this.$myVS.openNoti(res.data.Message, '', undefined)
        } else {
          this.$myVS.openNoti('發生錯誤，請稍候再試', res.data.Message, 'danger', 10000)
        }
      })
    },
    // 改變頁籤時觸發
    changePagination() {
      this.selected = []
      this.selectAll = false
      this.indeterminate = false
      this.getList([['page', this.currentPage]])
    },
    // 篩選狀態時觸發
    // statusOptions(e) {
    //   this.currentPage = 1
    //   this.loadPages()
    // },
    // 篩選標題時觸發
    search(e) {
      // 防止沒輸入文字也可以搜尋
      if (this.searchTitle || this.searchTitleBak) {
        if (this.searchTitle === this.searchTitleBak) return
        this.getList([
          ['page', 1],
          ['title', this.searchTitle],
        ])
      }
      this.searchTitleBak = this.searchTitle
    },
    checkBoxHandleClick(e) {
      setTimeout(() => {
        if (this.selected.length > 0 && this.selected.length < this.lists.length) {
          // 勾選卻沒有全選時
          this.indeterminate = true
        } else if (this.selected.length === this.lists.length) {
          // 全選時
          this.indeterminate = false
          this.$nextTick(() => {
            this.selectAll = true
          })
        } else {
          // 沒選時
          this.indeterminate = false
          this.selectAll = false
        }
      }, 0)
    },
    checkAllHandleClick(e, status) {
      let value
      e ? (value = e.target.checked) : (value = status)
      if (value) {
        for (let i = 0; i < this.lists.length; i++) {
          this.selected.push(this.lists[i].smcno)
        }
      } else {
        this.selected = []
      }
    },
    indeterminateHandleClick(e) {
      let value = e.target.checked
      this.checkAllHandleClick(null, value)
    },
  },
}
</script>

<style lang="scss" scoped>
// 標頭和列表
.header_wrap {
  @apply w-min md:w-full;
  // 標頭文字顯示區塊/列表文字顯示區塊
  .grid_section {
    @apply w-full items-center gap-6 grid;
    grid-template-columns: repeat(4, 150px);

    @screen md {
      grid-template-columns: repeat(4, minmax(0, 1fr));
    }
  }
}

.checkbox_section {
  @apply w-28 flex-shrink-0;
}
</style>
